import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import { ImageBackground, MainDiv } from "../components/styledComponents";
import { css } from "@emotion/react";
import Image from "../components/image";

const Welcome = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <Layout>
      <MainDiv
        css={css`
          margin-top: -47px;
          height: calc(100vh - 50px);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-family: "Brandon Grotesque", sans-serif;
          overflow: hidden;

          .top-image,
          .bottom-image {
            background-position: -10px -200px, center -2000px !important;
            flex: 1 0 100%;
            height: 5%;
            transform: skew(0deg, -5deg);
          }

          .top-image {
            width: 110vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }

          .bottom-image {
            width: 110vw;
          }
        `}
      >
        <ImageBackground
          tag={"div"}
          fluid={[
            `linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)`,
            Image().indexBg,
          ]}
          className="top-image"
        >
          <ImageBackground
            tag={"div"}
            fluid={Image().pattern2}
            css={css`
              height: 25px;

              p {
              }
            `}
          />
        </ImageBackground>
        <div
          css={css`
            p {
              max-width: 700px;
              font-size: 21px;
              color: #707070;
            }
          `}
          style={{ margin: "100px 0" }}
        >
          <h1>Where will your travels take you?</h1>
          <p>
            Enjoy a meal before your flight and pick up some travel and health
            necessities while being inspired by the essence of Zambia.
          </p>
          <p>
            A member of a people that travels from place to place to find fresh
            pasture for its animals and has no permanent home.
          </p>
          <p>A person who does not stay long in the same place; a wanderer.</p>
          <p>
            Feed your soul | Nourish your Inspiration | Connect with the essence
            of Zambia | Roam the roads of lands remote
          </p>
        </div>
        <ImageBackground
          tag={"div"}
          fluid={[
            `linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)`,
            Image().indexBg,
          ]}
          className="bottom-image"
        >
          <ImageBackground
            tag={"div"}
            fluid={Image().pattern2}
            css={css`
              height: 25px;
            `}
          />
        </ImageBackground>
      </MainDiv>
    </Layout>
  );
};

export default Welcome;
